import styled from "styled-components";

const Container = styled.div`
    height: 50px;
    width: 60%;
    margin: auto;
    margin-top: 20px;
`;

const Wrapper = styled.div`
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Left = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Logo = styled.h1`
    font-weight: bold;
    text-decoration: underline crimson;
    color: white;
`;

const Menu = styled.ul`
    display: flex;
    list-style: none;
`;
const MenuItem = styled.li`
    margin-right: 30px;
    font-size: 20px;
    font-weight: bold;
    color: gray;
`;

const Button = styled.button`
    border: 2px solid black;
    padding: 10px 15px;
    background-color: black;
    color: black;
`;

export default function Navbar() {
    return (
        <Container>
            <Wrapper>
                <Left>
                    <Logo>TNFP</Logo>
                </Left>
                <Menu>
                    <MenuItem>Home</MenuItem>
                    <MenuItem>About</MenuItem>
                    <MenuItem>Service</MenuItem>
                    <MenuItem>Contact</MenuItem>
                </Menu>
                <Button>button</Button>
            </Wrapper>
        </Container>
    );
}
