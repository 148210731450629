import styled from "styled-components";

const Container = styled.div`
    display: flex;
    width: 60%;
    margin: auto;
    flex-direction: column;
`;

const Line = styled.hr`
    color: white;
`;

const DisplayDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const Title = styled.h1`
    color: white;
    margin: 10px 10px 5px 10px;
    text-align: center;
`;

const ListDiv = styled.div`
    display: flex;
    margin-bottom: 10px;
`;

const ListLeft = styled.div`
    width: 50%;
`;

const ListRight = styled.div`
    width: 50%;
`;

const List = styled.ul``;

const ListItem = styled.li`
    color: white;
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
`;

export default function Services() {
    return (
        <Container>
            <Line />
            <Title>Commercial, Industrial and Residential</Title>
            <DisplayDiv>
                <ListDiv>
                    <ListLeft>
                        <List>
                            <ListItem>
                                Specializing in legal basement utility room fire
                                sprinkler system's NFPA13D
                            </ListItem>
                            <ListItem>
                                Inspections per NFPA and local requirements
                            </ListItem>
                            <ListItem>Water-flow testing</ListItem>
                            <ListItem>Fire pump testing</ListItem>
                            <ListItem>Alarm testing</ListItem>
                            <ListItem>Troubleshooting</ListItem>
                        </List>
                    </ListLeft>
                    <ListRight>
                        <List>
                            <ListItem>
                                Trip testing for dry pipe, deluge or pre-action
                                valves
                            </ListItem>
                            <ListItem>Acceptance Testing</ListItem>
                            <ListItem>Valve Maintainence</ListItem>
                            <ListItem>Obstruction Investigations</ListItem>
                            <ListItem>System Upgrades</ListItem>
                            <ListItem>System Modifications</ListItem>
                            <ListItem>24-hour service</ListItem>
                        </List>
                    </ListRight>
                </ListDiv>
            </DisplayDiv>
        </Container>
    );
}
