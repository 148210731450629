import styled from "styled-components";
import logo from "../../image/tnfp_logo_new.png";

const Container = styled.div`
    width: 60%;
    display: flex;
    margin: auto;
    flex-direction: column;
`;

const Line = styled.hr`
    color: white;
`;

const DisplayDiv = styled.div`
    display: flex;
    flex-direction: row;
    margin: 20px 0px 20px 0px;
`;

const Left = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70%;
`;

const Right = styled.div`
    display: flex;
    flex-direction: column;
    width: 30%;
`;

const DescLeft = styled.div`
    color: white;
    display: flex;
    flex-direction: column;
`;

const TitleLeft = styled.h2`
    color: white;
    font-size: 28px;
    margin: 5px 5px 5px 0px;
`;

const DetailLeft = styled.p`
    color: white;
    font-size: 20px;
    margin: 5px 5px 5px 0px;
`;

const Logo = styled.img`
    width: 80%;
    height: auto;
`;

export default function Bottom() {
    return (
        <Container>
            <Line />
            <DisplayDiv>
                <Left>
                    <DescLeft>
                        <TitleLeft>Contact</TitleLeft>
                        <DetailLeft>Ahmad Massih Sultanzada</DetailLeft>
                        <DetailLeft>Manager</DetailLeft>
                        <DetailLeft>Phone: (647) 271-1019</DetailLeft>
                        <DetailLeft>
                            Email: <b>torontonorthfireprotection@hotmail.com</b>
                        </DetailLeft>
                    </DescLeft>
                </Left>
                <Right>
                    <Logo src={logo} />
                </Right>
            </DisplayDiv>
        </Container>
    );
}
