import Navbar from "./components/Navbar/Navbar";
import Intro from "./components/Intro/Intro";
import styled from "styled-components";
import Particle from "./components/Particle/Particle";
import About from "./components/About/About";
import Services from "./components/Services/Services";
import Bottom from "./components/Bottom/Bottom";
// import Policy from "./components/Policy/Policy";

const Container = styled.div`
    height: 100vh;
    overflow: hidden;
    background-color: black;
`;

const ContainerAbout = styled.div`
    height: auto;
    overflow: hidden;
    background-color: black;
`;

const ContainerService = styled.div`
    height: auto;
    overflow: hidden;
    background-color: black;
`;

const ContainerBottom = styled.div`
    height: auto;
    overflow: hidden;
    background-color: black;
`;

//const ContainerPolicy = styled.div`
//    height: 100px;
//    overflow: hidden;
//    background-color: black;
//`;

export default function App() {
    return (
        <>
            <Container>
                <Navbar />
                <Intro />
                <Particle />
            </Container>
            <ContainerAbout>
                <About />
            </ContainerAbout>
            <ContainerService>
                <Services />
            </ContainerService>
            <ContainerBottom>
                <Bottom />
            </ContainerBottom>
        </>
    );
}
