import styled from "styled-components";
import sprinkler1 from "../../image/sprinkler1.jpg";

const Container = styled.div`
    height: 100vh;
    width: 60%;
    margin: auto;
    display: flex;
    padding: 20px;
    position: relative;
    margin-top: 10px;
`;

const ImageDiv = styled.div`
    width: 100%;
    height: 80%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:before {
        content: "";
        background-image: url(${sprinkler1});
        background-size: cover;
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        opacity: 0.5;
    }
`;

const Title = styled.h1`
    font-size: 50px;
    color: #ffffff;
    text-align: center;
    z-index: 1;
`;

const Desc = styled.p`
    font-size: 20px;
    margin-top: 20px;
    color: white;
    text-align: center;
    z-index: 1;
`;

const Info = styled.div`
    width: 60%;
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
`;

const Button = styled.button`
    padding: 15px;
    background-color: darkred;
    color: white;
    border-radius: 10px;
    font-weight: bold;
    border: none;
    letter-spacing: 1px;
    cursor: pointer;
`;

const Contact = styled.div`
    display: flex;
    flex-direction: column;
`;

const Phone = styled.span`
    color: white;
    font-weight: bold;
`;

const ContactText = styled.span`
    color: lightgray;
    margin-top: 5px;
`;

const ImageDesc = styled.a`
    display: none;
`;

export default function Intro() {
    return (
        <Container>
            <ImageDiv>
                <ImageDesc href="https://www.vecteezy.com/free-photos">
                    Free Stock photos by Vecteezy
                </ImageDesc>
                <Title>Toronto North Fire Protection</Title>
                <Desc>
                    To save live, properties and to have all fire systems ready
                    for the fire department.
                </Desc>
                <Info>
                    <Button>START A PROJECT</Button>
                    <Contact>
                        <Phone>Call (647) 271 1019</Phone>
                        <ContactText> For any question or concern</ContactText>
                    </Contact>
                </Info>
            </ImageDiv>
        </Container>
    );
}
