import styled from "styled-components";
import sprinkler2 from "../../image/sprinkler2.jpg";

const Container = styled.div`
    display: flex;
    width: 60%;
    margin: auto;
    flex-direction: column;
`;

const Line = styled.hr`
    color: white;
`;

const DisplayDiv = styled.div`
    display: flex;
    flex-direction: row;
    margin: 10px 0 5px 0;
`;

const Left = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const AboutTitle = styled.h1`
    color: white;
`;

const Desc = styled.p`
    width: 70%;
    color: white;
    margin-top: 20px;
    font-size: 20px;
    text-align: center;
`;

const Right = styled.div`
    width: 50%;
`;

const Pic = styled.img`
    width: 100%;
    height: auto;
`;

export default function About() {
    return (
        <Container>
            <Line />
            <DisplayDiv>
                <Left>
                    <AboutTitle>About</AboutTitle>
                    <Desc>
                        With over 15 years in the industry, we have doont it
                        all. From high rise buildings to thhe industril units.
                        All of our technicians are fully licensed and insured.
                        Proudly members of Local Union 853.
                    </Desc>
                </Left>
                <Right>
                    <Pic src={sprinkler2} />
                </Right>
            </DisplayDiv>
        </Container>
    );
}
